body {
  background-color: #1d8164;
}

.scope {
  width: 210mm;
  margin-left: auto;
  margin-right: auto;
}

.page {
  font-family: Noto Sans, Noto Sans Kr;
  -webkit-print-color-adjust: exact;
  background-color: #ffffff;
  overflow: hidden;
  height: 297mm;
  width: 210mm;
  /* to centre page on screen*/
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.hide-scrollbar {
	overflow: -moz-hidden-unscrollable;
	overflow: hidden;
}
.infoRow {
  border-top-width: 4px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-top-color: #1d8164;
  border-bottom-color: #8ec0b1;
  display: flex;
  flex-direction: row;
}

.label {
  padding: 19px 22px;
  text-align: center;
  font-size: 13px;
  font-weight: 900;
  color: #1d8164;
  background-color: #daf1ea;
  margin-bottom: 0px;
  text-align: center;
  font-family: Noto Sans, Noto Sans Kr sans-serif;
}
.infoText {
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-bottom: 0px;
  font-family: Noto Sans, Noto Sans Kr sans-serif;
}
.midSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.midRow {
  width: 100%;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  flex: 1;
}
.midHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px 10px;
}
.grayLabel {
  color: #666666;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
}
.date {
  font-weight: 300;
  font-size: 14px;
}
.asterisk {
  color: #989898;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0;
}

.grayTable {
  background-color: #f6f6f6;
  text-align: center;
}
th {
  padding: 8px 0px;
  border-width: 1 solid black;
}
td {
  padding: 12px 0px;
}
.thText {
  font-size: 12px;
  font-weight: 600;
  color: #666666;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  border-right: 0.5px solid silver;
  border-bottom: 0.5px solid silver;
  border-collapse: collapse;
}
.levelBar {
  width: 19%;
  height: 6px;
}
.levelCircle {
  height: 25px;
  width: 25px;
  border-radius: 15px;
  background-color: #fff;
}
.levelText {
  text-align: center;
  font-size: 15px;
  font-weight: bolder;
  margin-top: -1px;
}
.levelCircleView {
  position: absolute;
  width: 250px;
}
.barInfo {
  width: 250px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.barRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}
.myScore {
  color: rgb(29, 129, 100);
  background-color: rgb(255 255 255);
  width: 160px;
  border: 2px solid #1d8164;
}
.arrow {
  width: 60px;
}
.tHeader {
  display: flex;
  flex-direction: row;
  height: 20px;
}
.tRow {
  display: flex;
  flex-direction: row;
  height: 40px;
}
.tColumn {
  width: 60px;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
}
.tOldValue {
  width: 140px;
  font-size: 18px;
}
.tNewValue {
  color: #1d8164;
  width: 170px;
  background-color: #fff;
}
.tNewValueText {
  font-size: 20px;
  font-weight: bold;
}
.tNewValueSmallText {
  font-size: 12px;
  font-weight: normal;
}
.secondTRow {
  width: 290px;
  padding-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrowText {
  position: absolute;
  display: flex;
  color: #fff;
  font-size: 16px;
  width: 60px;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
}
.sameData {
  height: 215px;
  padding: 10px;
  border-bottom: 1px solid #1d8164;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sameDataChild {
  flex-direction: row;
  width: 90%;
  display: flex;
  align-items: flex-end;
}
.percentBarParent {
  width: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.percentBar {
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background: linear-gradient(
    0.25turn,
    rgb(254, 111, 98),
    rgb(245, 201, 104),
    rgb(25, 210, 134)
  );
  overflow: hidden;
}
.percentBarChild {
  width: 220px;
  height: 14px;
  border-radius: 8px;
  background: linear-gradient(
    0.25turn,
    rgb(254, 111, 98),
    rgb(245, 201, 104),
    rgb(25, 210, 134)
  );
}
.chartValue {
  width: 150px;
  height: 150px;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-top: 10px;
  font-size: 30px;
  font-weight: bold;
}
.box {
  width: 50px;
  background-color: #fff;
  border-right: 0.5px solid #989898;
  border-top: 0px;
  border-left: 0px;
  font-size: 12;
}
.staticsDate {
  font-size: 10px;
  width: 50;
  border: 0px;
  padding-top: 0px;
}
.leftColumn {
  width: 50%;
  border-width: 0px 0px 1px;
  border-style: solid;
  border-color: silver;
}
.midleColumn {
  width: 15%;
  border-width: 0px 1px 1px;
  border-style: solid;
  border-color: silver;
}
.rightColumn {
  width: 35%;
  border-width: 0px 0px 1px;
  border-style: solid;
  border-color:silver;
}

.gameResultsRow {
  display: flex;
  flex-direction: row;
  height: 30px;
}
.gameResultsText {
  font-size: 12px;
  color: #000;
  padding-top: 7px;
}
.noBorderRight {
  border-right: 0px;
}
.noBorderLeft {
  border-left: 0px;
}
.header {
  width: 100%;
  height: 28px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #74b8a3;
}
.headerTitle {
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  padding: 5px;
  padding-left: 20px;
}
.gameResultsChartText {
  width: 19%;
}
.gameResultsChart {
  width: 81%;
  padding: 10px;
  padding-right: 25px;
  display: flex;
}
.gameResultsBar {
  width: 557px;
  height: 8px;
  background: linear-gradient(
    0.25turn,
    rgb(254, 111, 98),
    rgb(245, 201, 104),
    rgb(25, 210, 134)
  );
}
.gameResultBarParent {
  overflow: hidden;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 3px;
  height: 8px;
}
.gameResultsPercent {
  color: #000;
  background-color: #fff;
  width: 13px;
  font-size: 14px;
  font-weight: bold;
  margin-top: -7px;
}
.test {
  width: 557px;
  height: 8px;
}
.bottomTitle {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  width: 100%;
  margin-bottom: 5px;
}
.bottomText {
  color: #161515;
  font-size: 12px;
  margin-bottom: 5px;
}
.copyright {
  font-size: 10px;
  display: flex;
  align-self: flex-end;
  font-weight: normal;
  color: #a29a9a;
}
.bottom {
  flex-direction: column;
  display: flex;
  width: 100%;
  border-bottom: 1px solid;
  border-color: #33af3f;
  margin-top: 2px;
  height: 160[x];
}
.xValue {
  width: 50px;
  justify-content: center;
  color: #1d8164;
  font-weight: bold;
  font-size: 13px;
  line-height: 12px;

}
.firstData {
  background-color: #f6f6f6;
}
